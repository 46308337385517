import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

const Wrapper = styled.div`
  /* margin: 32px 0px; */
  margin-top: 40px;
  width: 100%;

  @media ${({ theme }) => theme.breakpoint.md} {
    margin: 62px 0px;
  }
`

const ShowreelWrapper = styled.div`
  /* margin: 32px 0px; */
  margin-top: 104px;
  overflow: hidden;
  margin-left: -7.5px;
  margin-right: -7.5px;
  padding-top: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 88px;
    margin-left: initial;
    margin-right: initial;
    overflow: visible;
  }
`

const StatsWrapper = styled.div`
  @media ${({ theme }) => theme.breakpoint.md} {
    margin: 32px 0px;
  }
`

const Summary = styled.div`
  color: #003d6a;

  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
  }
`

const StatTitle = styled.div`
  margin-top: 16px;
  color: #6fc2b4;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
  span {
    display: none;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    span {
      display: initial;
    }
    font-size: 50px;
    font-weight: 600;
    line-height: 45px;
  }
`

const StatSubtitle = styled.div`
  /* margin: 8px 16px 0px 16px; */
  margin-top: 8px;
  color: #003d6a;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  @media ${({ theme }) => theme.breakpoint.md} {
    span {
      display: none;
    }
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 20px;
  }
`

const ShowreelImage = styled.img`
  position: relative;
  object-fit: cover;
  object-position: center center;
  display: block;
  width: 100%;
  max-height: 240px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.26);
  margin-bottom: 0px;
`

const ShowreelTitle = styled.div`
  span {
    font-weight: 400;
  }
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 5px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 16px;
  }
`

const Date = styled.div`
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  float: right;
  margin-top: 36px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    /* margin-top: 16px; */
    margin-top: 36px;
  }
`

const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1.3,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 10000,
  draggable: true,
  accessibility: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick",
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "30px",

        slidesToScroll: 1,
        infinite: true,
        // dots: true,
      },
    },
  ],
}

const StyledSlider = styled(Slider)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    .slick-list {
      overflow: visible;
    }
    width: 100%;
    margin-bottom: 50px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    all: inherit;
    margin-left: 0;
    margin-right: 0;
  }
`
const FundsContent = ({ summary, stats, showreel, statsDate }) => {
  return (
    <Container overflowDisabled>
      <Wrapper>
        <Row>
          <Col md={8}>
            <Summary dangerouslySetInnerHTML={{ __html: summary }} />
          </Col>
        </Row>
        <StatsWrapper>
          <Row>
            {stats &&
              stats.map(stat => (
                <Col xs={6} sm={3} md={3} xl={3}>
                  <StatTitle>
                    {stat.title}{" "}
                    <span className="title-sqm">
                      {stat.title === "241,761" ? "sqm" : ""}
                    </span>
                  </StatTitle>
                  <StatSubtitle>
                    <span className="content-sqm">
                      {stat.title === "241,761" ? "sqm " : ""}
                    </span>
                    {stat.subtitle}
                  </StatSubtitle>
                </Col>
              ))}
          </Row>
          <Date>{statsDate}</Date>
        </StatsWrapper>
        <ShowreelWrapper>
          <Row>
            <StyledSlider {...settings}>
              {showreel &&
                showreel.map(item => (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <ShowreelImage
                      src={item.image.sourceUrl}
                      alt={item.image.alt}
                    />
                    <ShowreelTitle>
                      <span>Asset: </span>
                      {item.title}
                    </ShowreelTitle>
                  </Col>
                ))}
            </StyledSlider>
          </Row>
        </ShowreelWrapper>
      </Wrapper>
    </Container>
  )
}

export default FundsContent
